<template>
  <div>
    <label class="text-muted">All 2-Letter Words:</label>

    <br />
    <div>
      <div
        v-for="(wordList, index) in allFoundWords.matchingWords"
        v-bind:key="index"
      >
        <span class="navbar navbar-expand-lg navbar-dark bg-primary">
          {{ wordList.category }} Letter Words
        </span>

        <ul>
          <li v-for="(word, index) in wordList.words" v-bind:key="index">
            <Word :word="word" />
          </li>
        </ul>
      </div>

      <br />
    </div>
  </div>
</template>

<script>
import wordListerService from "../../services/wordListerService";
import Word from "@/components/Word.vue";
import NProgress from "nprogress";

export default {
  name: "TwoLetterWords",
  components: {
    Word
  },
  data() {
    return {
      errors: [],
      letters: [],
      allFoundWords: [],
      AllWordsInArray: [],
      AllWordsInArraysCombinedTogether: [],
      rawResultsFromQuery: null,
      CharVersionOfUpperCaseLetters: [],
      UpperCaseLettersRearranged: [],
      UpperCaseLettersRearrangedForAPI: [],
      testingWordList: [],
      showTargetLetters: false
    };
  },
  mounted: function() {
    //flush all variables for a new query
    this.showTargetLetters = false;
    this.errors = [];
    this.allFoundWords = [];
    this.rawResultsFromQuery = null;
    this.CharVersionOfUpperCaseLetters = [];
    this.UpperCaseLettersRearranged = [];
    this.UpperCaseLettersRearrangedForAPI = [];
    this.AllWordsInArraysCombinedTogether = [];

    //this.showTargetLetters = true;
    //var UpperCaseLetters = this.letters.toUpperCase();
    //this.CharVersionOfUpperCaseLetters = UpperCaseLetters.split("");
    //this.checkForWildCards(this.CharVersionOfUpperCaseLetters);
    this.findMatches();
  },

  methods: {
    format(letters) {
      return `<strong> ${letters} </strong>`;
    },

    findMatches() {
      NProgress.start();
      wordListerService
        .findTwoLetterWords()
        .then(response => {
          NProgress.done();
          this.allFoundWords = response.data;
        })
        .catch(error => this.errors.push(error));
    },

    checkForMoreThanTwoWildCards(lettersToCheck) {
      var countWildCards = 0;
      for (var a = 0; a < lettersToCheck.length; a++) {
        if (lettersToCheck[a] == "?") {
          countWildCards = countWildCards + 1;
        }
      }
      if (countWildCards > 2) {
        return true;
      } else {
        return false;
      }
    },

    checkForWildCards(CharVersionOfUpperCaseLetters) {
      var localVersionOfEnteredChars = CharVersionOfUpperCaseLetters;
      var newArrayOfChars = new Array();
      var newArrayOfCharsForAPI = new Array();
      var questionMarks = new Array();
      var astersisksForAPI = new Array();

      var i;
      for (i = 0; i < localVersionOfEnteredChars.length; i++) {
        if (localVersionOfEnteredChars[i] == "?") {
          questionMarks.push(localVersionOfEnteredChars[i]);
          astersisksForAPI.push("*");
        } else {
          newArrayOfChars.push(localVersionOfEnteredChars[i]);
          newArrayOfCharsForAPI.push(localVersionOfEnteredChars[i]);
        }
      }
      var combinedArray = new Array();
      var combinedArrayForAPI = new Array();
      if (questionMarks) {
        //combine the two arrays
        combinedArray = newArrayOfChars
          .concat(questionMarks)
          .join("")
          .split("");

        combinedArrayForAPI = newArrayOfCharsForAPI
          .concat(astersisksForAPI)
          .join("")
          .split("");
      } else {
        combinedArray = newArrayOfChars.join("").split("");
        combinedArrayForAPI = newArrayOfCharsForAPI.join("").split("");
      }

      //create a string for the rearranged letters
      var chosenLettersString = combinedArray.join("");
      var chosenLettersStringForAPI = combinedArrayForAPI.join("");

      //reset the array variables
      questionMarks = [];
      newArrayOfChars = [];
      combinedArray = [];

      astersisksForAPI = [];
      newArrayOfCharsForAPI = [];
      combinedArrayForAPI = [];

      //assign the value of the string to instance variables
      this.UpperCaseLettersRearranged = chosenLettersString;
      this.UpperCaseLettersRearrangedForAPI = chosenLettersStringForAPI;
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
